
import { defineComponent, watch } from "vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "underwriters-clients-table",
  props: {
    widgetClasses: String,
    title: String,
    clients: Array,
  },
  setup(props) {
    const { isEmptyArray, displayDatatable } = ResusableFunctions();

    watch(
      () => props.clients,
      (newClients: any) => {
        if (!isEmptyArray(newClients)) {
          displayDatatable(["basicExample"]);
        }
      }
    );
  },
  methods: {
    addCommaToNumber(event) {
      variables.addCommaToNumber(event);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
